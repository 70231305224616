import React, { useContext, useEffect } from "react";
import {
	Navigate,
	useRoutes,
	Route,
	Switch,
	NavLink,
	Routes,
	useLocation,
} from "react-router-dom";

import Login from "../Components/Login";
import Domains from "../Components/Domains/Domains";
import Domain from "../Components/Domains/Domain";
import Dashboard from "../Components/Dashboard";
import SubdomainEdit from "../Components/Subdomains/SubdomainEdit";
import { Context as AuthContext } from "../context/AuthContext";
import Loading from "../Components/Loading";
import PusherLoader from "../Components/PusherLoader";
import AlertMessage from "../Components/Helpers/AlertMessage";
import Navigation from "../Components/Navigation";
import Notifications from "../Components/Notifications/Notifications";

import { Context as AlertContext } from "../context/AlertContext";
import Register from "../Components/Register";
import MainContainer from "../Components/Containers/MainContainer";
import SideMenu from "../Components/Containers/SideMenu";
import Settings from "../Components/Settings";
import Billing from "../Components/Billing";
import MyProfile from "../Components/MyProfile";
import Subdomain from "../Components/Subdomains/Subdomain";
import Footer from "../Components/Containers/Footer";
import DomainDns from "../Components/Domains/DomainDns";
import DomainWhois from "../Components/Domains/DomainWhois";
import SubdomainSSL from "../Components/Subdomains/SubdomainSSL";
import SubdomainMonitoring from "../Components/Subdomains/SubdomainMonitoring";
import SubdomainNotifications from "../Components/Subdomains/SubdomainNotifications";

const Router = (props) => {
	const { state, checklogin, logout } = useContext(AuthContext);
	const { setalert } = useContext(AlertContext);
	const location = useLocation();

	useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search);
		const message = queryParams.get("message");
		if (message) {
			let type = "success";
			if (queryParams.get("error") == "true") {
				type = "danger";
			}
			setalert(type, message);
			queryParams.delete("message");
			queryParams.delete("error");
			window.history.replaceState(
				{},
				"",
				`${window.location.pathname}?${queryParams}${window.location.hash}`
			);
		}
		checklogin(localStorage.getItem("loggedIn") == "true" || false);
	}, []);

	if (localStorage.getItem("loggedIn") != "true") {
		return (
			<Routes>
				<Route path="*" element={<Login />} />
				<Route path="/login" element={<Login />}></Route>
				<Route path="/register" element={<Register />}></Route>
			</Routes>
		);
	}

	if (location.pathname == "/register" || location.pathname == "/login") {
		console.log("it matches bitch", location);
		return <Navigate to="/" />;
	}

	if (!state.loaded) {
		return <Loading></Loading>;
	}

	return (
		<div class="layout-container">
			<PusherLoader></PusherLoader>

			<MainContainer>
				<Routes>
					<Route path="/" element={<Dashboard />}></Route>

					<Route path="/settings" element={<Settings />}></Route>
					<Route path="/billing" element={<Billing />}></Route>
					<Route path="/my-profile" element={<MyProfile />}></Route>
					<Route path="/domains" element={<Domains />}></Route>
					<Route path="/domain/:id" element={<Domain />} />
					<Route path="/domain/:id/dns" element={<DomainDns />} />
					<Route path="/domain/:id/whois" element={<DomainWhois />} />

					<Route path="/subdomain/:id" element={<Subdomain />} />
					<Route path="/subdomain/:id/settings" element={<SubdomainEdit />} />
					<Route path="/subdomain/:id/ssl" element={<SubdomainSSL />} />
					<Route
						path="/subdomain/:id/monitoring"
						element={<SubdomainMonitoring />}
					/>
					<Route
						path="/subdomain/:id/notifications"
						element={<SubdomainNotifications />}
					/>
				</Routes>
			</MainContainer>
		</div>
	);
};

export default Router;
