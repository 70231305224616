import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import * as dayjs from "dayjs";
import { Context as AuthContext } from "../../context/AuthContext";
import SubdomainWrapper from "./SubdomainWrapper";
import loadSubdomain from "./loadSubdomain";
const SubdomainMonitoring = (props) => {
    const params = useParams();
	const [subdomain, setSubdomain] = useState(null);
	const { logout } = useContext(AuthContext);
    useEffect(() => {
		loadSubdomain(params.id, setSubdomain, logout);
	}, []);
    const httpStatusInfoClasses = () => {
		const status = parseInt(subdomain?.http_status);

		console.log("status is ", status);
		if (!subdomain || status < 200) {
			return "";
		}
		if (status >= 200 && status < 300) {
			return "success bx-check";
		}
		if (status >= 300 && status < 400) {
			return "warning bx-error-circle";
		}
		if (status >= 400) {
			return "error bx-error";
		}
	};

	const subddomainHttpStatus = subdomain?.http_info?.response_headers.map(
		(header) => {
			const headerElement = header.split(/:(.*)/s);

			return (
				<div style={{ fontSize: "0.8rem" }}>
					<span class="text-capitalize">
						<strong>{headerElement[0]}</strong>:
					</span>
					<span class="mx-2 " style={{ overflowWrap: "anywhere" }}>
						{headerElement[1]}
					</span>
				</div>
			);
		}
	);
	return (
		<>
			{subdomain ? (
				<SubdomainWrapper subdomain={subdomain}>
					<div class="card mb-4">
						<h5 class="card-header">HTTP Monitoring</h5>
						<table class="table table-borderless">
							<tbody>
								<tr>
									<td class="align-middle">
										<small class="text-light fw-semibold">HTTP Status</small>
									</td>
									<td class="py-3">
										<p class="mb-0">
											<strong>
												<i
													className={"bx bx-sm " + httpStatusInfoClasses()}
												></i>
												{subdomain?.http_info?.status_code}
											</strong>
										</p>
									</td>
								</tr>
								<tr>
									<td class="align-middle">
										<small class="text-light fw-semibold">
											Response Headers
										</small>
									</td>
									<td class="py-4">
										<p class="mb-0">{subddomainHttpStatus}</p>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</SubdomainWrapper>
			) : null}
		</>
	);
};

export default SubdomainMonitoring;
