import apiClient from "../../Services/api";

const loadSubdomain = (id, callback, logout) => {
	console.log("loading subdomain");
	apiClient
		.get("/api/subdomains/" + id)
		.then((response) => {
			console.log("data is", response.data.data);
			callback(response.data.data);
		})
		.catch((error) => {
			if (error.response.status == 401) {
				logout();
			}
			console.error(error);
		});
};
export default loadSubdomain;
