import React from "react";

import { NavLink } from "react-router-dom";
const SubdomainSubmenu = (props) => {
	const subdomain = props.subdomain;

	return (
		<div class="col col-xl-2">
			<div class="layout-menu menu-vertical menu">
				<ul class="menu-inner p-5">
                <li class="menu-item small ">
						<NavLink
							end
							activeClassName="active"
							to={`/subdomain/${subdomain.id}`}
							className="menu-link text-muted"
						>
							<i class="menu-icon tf-icons bx bx-list-ul"></i>
							<div>Overview</div>
						</NavLink>
					</li>
                    <li class="menu-item small ">
						<NavLink
							end
							activeClassName="active"
							to={`/subdomain/${subdomain.id}/ssl`}
							className="menu-link text-muted"
						>
							<i class="menu-icon tf-icons bx bx-analyse"></i>
							<div>SSL</div>
						</NavLink>
					</li>
                    <li class="menu-item small ">
						<NavLink
							end
							activeClassName="active"
							to={`/subdomain/${subdomain.id}/monitoring`}
							className="menu-link text-muted"
						>
							<i class="menu-icon tf-icons bx bx-target-lock"></i>
							<div>Http Monitoring</div>
						</NavLink>
					</li>
                    <li class="menu-item small ">
						<NavLink
							end
							activeClassName="active"
							to={`/subdomain/${subdomain.id}/notifications`}
							className="menu-link text-muted"
						>
							<i class="menu-icon tf-icons bx bx-notification"></i>
							<div>Notifications</div>
						</NavLink>
					</li>
					<li class="menu-item small ">
						<NavLink
							end
							activeClassName="active"
							to={`/subdomain/${subdomain.id}/settings`}
							className="menu-link text-muted"
						>
							<i class="menu-icon tf-icons bx bx-cog"></i>
							<div>Settings</div>
						</NavLink>
					</li>
				
				</ul>
			</div>
		</div>
	);
};

export default SubdomainSubmenu;
