import React from "react";

import { NavLink } from "react-router-dom";
const DomainSubmenu = (props) => {
	const domain = props.domain;

	return (
		<div class="col col-xl-2">
			<div class="layout-menu menu-vertical menu">
				<ul class="menu-inner p-5">
					<li class="menu-item small ">
						<NavLink
							end
							activeClassName="active"
							to={`/domain/${domain.id}`}
							className="menu-link text-muted"
						>
							<i class="menu-icon tf-icons bx bx-home-circle"></i>
							<div>Subdomains</div>
						</NavLink>
					</li>
					<li class="menu-item small ">
						<NavLink
							end
							activeClassName="active"
							to={`/domain/${domain.id}/dns`}
							className="menu-link text-muted"
						>
							<i class="menu-icon tf-icons bx bx-server"></i>
							<div>DNS</div>
						</NavLink>
					</li>
					<li class="menu-item small ">
						<NavLink
							end
							activeClassName="active"
							to={`/domain/${domain.id}/whois`}
							className="menu-link text-muted"
						>
							<i class="menu-icon tf-icons bx bx-info-square"></i>
							<div>Whois</div>
						</NavLink>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default DomainSubmenu;
