import React from "react";
import Table from "react-bootstrap/Table";
import SubdomainTableRow from "./SubdomainTableRow";

const Subdomains = (props) => {
	const subdomainsList = props.subdomains.map((subdomain) => (
		<SubdomainTableRow
			subdomain={subdomain}
			subdomainDeleted={props.subdomainDeleted}
		/>
	));

	return (
		<div class="card">
			<div class="table-responsive text-nowrap">
				<table class="table">
					<thead>
						<tr>
							<th>Name</th>
							<th>SSL ISSUER</th>
							<th>SSL EXPIRY DATE	</th>
                            <th>SSL Port</th>
                            <th>Http status</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody class="table-border-bottom-0">{subdomainsList}</tbody>
				</table>
			</div>
		</div>
	);
};

export default Subdomains;
