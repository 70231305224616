import React from "react";
import Notifications from "./Notifications/Notifications";

const Settings = () => {
	return (
		<>
		
			<Notifications />
		</>
	);
};

export default Settings;
