import createDataContext from "./createDataContext";

const userReducer = (state, action) => {
	switch (action.type) {
		case "set_user":
			console.log("adding user", state, action);
			localStorage.setItem("userId", action.payload.id);
			return { ...state, user: action.payload };
		default:
			return state;
	}
};

const setuser = (dispatch) => (user) => {
	dispatch({
		type: "set_user",
		payload: user,
	});
};
export const { Provider, Context } = createDataContext(
	userReducer,
	{ setuser },
	{ user: null }
);
