import createDataContext from "./createDataContext";

import apiClient from "../Services/api";

const authReducer = (state, action) => {
	switch (action.type) {
		case "update_loggedin":
			return {
				...state,
				loggedIn: action.payload.loggedIn,
				user: action.payload.user,
			};
		case "update_loaded":
			return { ...state, loaded: action.payload.loaded };
		case "login":
			return {
				...state,
				user: action.payload.user,
				loggedIn: true,
				loaded: true,
			};
		case "logout":
			return { ...state, user: {}, loggedIn: false };
		default:
			return state;
	}
};

const login = (dispatch) => (user) => {
	localStorage.setItem("loggedIn", true);
	dispatch({
		type: "login",
		payload: { user },
	});
};
const logout = (dispatch) => () => {
	console.log("logout");
	apiClient.post("/logout").then((response) => {
		if (response.status === 204) {
			localStorage.setItem("loggedIn", false);
			dispatch({
				type: "logout",
				payload: {},
			});
		}
	});
};
const checklogin = (dispatch) => (loggedIn) => {
	apiClient
		.get("/api/user")
		.then((response) => {
			localStorage.setItem("userId", response.data.user.id);
			dispatch({
				type: "update_loggedin",
				payload: { loggedIn, user: response.data.user },
			});

			dispatch({
				type: "update_loaded",
				payload: { loaded: true },
			});
		})
		.catch((error) => {
            localStorage.setItem("loggedIn", false);
			dispatch({
				type: "logout",
				payload: {},
			});
		});
};

export const { Provider, Context } = createDataContext(
	authReducer,
	{ checklogin, login, logout },
	{ loggedIn: false, loaded: false, user: {} }
);
