import React, { useContext, useState, useEffect } from "react";

import apiClient from "../Services/api";
import { NavLink } from "react-router-dom";
import { Context as AuthContext } from "../context/AuthContext";
import { Context as PusherContext } from "../context/PusherContext";
import * as dayjs from "dayjs";
import Domains from "./Domains/Domains";
const Dashboard = (props) => {
	const { state } = useContext(AuthContext);
	const [userInfo, setUserInfo] = useState([]);
	const loadUserInfo = () => {
		console.log("loading user info");
		apiClient
			.get("/api/user-info")
			.then((response) => {
				console.log("data is", response.data.user_info);
				setUserInfo(response.data.user_info);
			})
			.catch((error) => {
				console.error(error);
			});
	};
	useEffect(() => {
		loadUserInfo();
	}, []);

	const expiringDomains = userInfo.expiring_domains
		? userInfo.expiring_domains
		: [];
	const expiringDomainsList = expiringDomains.map((domain) => (
		<li>
			<NavLink to={"/domain/" + domain.id} style={{ textDecoration: "none" }}>
				<span class="mr-2 font-weight-bold">
					<strong>{domain.name}</strong>
				</span>
				{" - "}
				<span>
					{dayjs(domain.expiry_date).format("MMMM D") +
						" (" +
						dayjs(domain.expiry_date).diff(dayjs(), "days") +
						" days)"}
				</span>
			</NavLink>
		</li>
	));

	const expiringSSL = userInfo.expiring_ssl ? userInfo.expiring_ssl : [];
	const expiringSSLList = expiringSSL.map((subdomain) => (
		<li>
			<NavLink
				to={"/subdomain/" + subdomain.id}
				style={{ textDecoration: "none" }}
			>
				<span class="mr-2 font-weight-bold">
					<strong>{subdomain.name}</strong>
				</span>
				{" - "}
				<span>
					{dayjs(subdomain.ssl_expiry_date).format("MMMM D") +
						" (" +
						dayjs(subdomain.ssl_expiry_date).diff(dayjs(), "days") +
						" days)"}
				</span>
			</NavLink>
		</li>
	));

	return (
		<div class="py-3">
			<div class="row">
				<div class="col-lg-12 mb-4 order-0">
					<div class="card">
						<div class="d-flex align-items-end row">
							<div class="card-body">
								<h5 class="card-title text-primary">
									<i class="menu-icon tf-icons bx bx-health"></i>Application
									Health
								</h5>
								<div class="row mt-2">
									<div class="col-lg-6">
										<h6>Domains Expiring Soon</h6>
										<ul>{expiringDomainsList}</ul>
									</div>
									<div class="col-lg-6">
										<h6>SSL Certificates Expiring Soon</h6>
										<ul>{expiringSSLList}</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
            <Domains/>
		</div>
	);
};

export default Dashboard;
