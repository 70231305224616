import React, { useContext, useState } from "react";
import apiClient from "../../Services/api";
import * as dayjs from "dayjs";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import { Context as AuthContext } from "../../context/AuthContext";
import { Context as PusherContext } from "../../context/PusherContext";
import { Context as AlertContext } from "../../context/AlertContext";
import DropdownItem from "../Helpers/Dropdowns/DropdownItem";
import DropdownMenu from "../Helpers/Dropdowns/DropdownMenu";
const SubdomainTableRow = (props) => {
	const navigate = useNavigate();
	const { state } = useContext(PusherContext);
	const { logout } = useContext(AuthContext);
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const { setalert } = useContext(AlertContext);
	const editSubdomain = (e) => {
		e.preventDefault();
		navigate("/subdomain/" + props.subdomain.id + "/settings");
	};

	const toggleDropdown = () => {
		setDropdownOpen(!dropdownOpen);
	};

	const closeDropdown = () => {
		setDropdownOpen(false);
	};

	let channel = state.channel;

	if (channel) {
		channel.bind(
			"subdomain-updated",
			function (data) {
				if (data.id == props.subdomain.id) {
					//do something when ssl was updated
				}
			}
			// channel.unbind()
		);
	}

	const deleteSubdomain = (e) => {
		e.preventDefault();
		const confirmation = window.confirm("Are uou sure ?");
		if (!confirmation) {
			return false;
		}
		apiClient
			.delete("/api/subdomains/" + props.subdomain.id, {})
			.then((response) => {
				if (response.status === 200) {
					closeDropdown();
					setalert("success", "Subdomain Deleted");
					props.subdomainDeleted(props.subdomain);
				}
			})
			.catch((error) => {
				setalert("error", "Failed to delete subdomain!");
				if (error.response.status == 401) {
					logout();
				}
				console.error(error);
			});
	};

	const reloadSSLInfo = (e) => {
		e.preventDefault();
		apiClient
			.post("/api/subdomain/reload-ssl/" + props.subdomain.id, {})
			.then((response) => {
				if (response.status === 200) {
					closeDropdown();
				}
			})
			.catch((error) => {
				if (error.response.status == 401) {
					logout();
				}
				console.error(error);
			});
	};
	return (
		<tr key={props.subdomain.id}>
			<td>
				<NavLink
					to={"/subdomain/" + props.subdomain.id}
					style={{ textDecoration: "none" }}
				>
					<strong>{props.subdomain.name}</strong>
				</NavLink>
			</td>
			<td>{props.subdomain.ssl_issuer}</td>
			<td>
				{props.subdomain.ssl_expiry_date
					? dayjs(props.subdomain.ssl_expiry_date).format(
							"MMMM D, YYYY h:mm A"
					  ) +
					  " (" +
					  dayjs(props.subdomain.ssl_expiry_date).diff(dayjs(), "days") +
					  " days)"
					: "N/A"}
			</td>
			<td>{props.subdomain.ssl_port}</td>
			<td>{props.subdomain.http_status}</td>
			<td>
				<div class="dropdown">
					<button
						type="button"
						class="btn p-0 dropdown-toggle hide-arrow"
						data-bs-toggle="dropdown"
						onClick={toggleDropdown}
					>
						<i class="bx bx-dots-vertical-rounded"></i>
					</button>
					<DropdownMenu
						key={props.subdomain.id}
						open={dropdownOpen}
						closeDropdown={closeDropdown}
					>
						<DropdownItem
							clicked={editSubdomain}
							classes="bx-edit-alt"
							key={"edit_" + props.subdomain.id}
							text="Edit"
						></DropdownItem>
						<DropdownItem
							clicked={deleteSubdomain}
							classes="bx-trash"
							key={"delete_" + props.subdomain.id}
							text="Delete"
						></DropdownItem>
						<DropdownItem
							clicked={reloadSSLInfo}
							classes="bx-refresh"
							key={"refresh_" + props.subdomain.id}
							text="Run Now"
						></DropdownItem>
					</DropdownMenu>
				</div>
			</td>
		</tr>
	);
};

export default SubdomainTableRow;
