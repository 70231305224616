import React from "react";
import Breadcrumb from "../Helpers/Breadcrumb";
import SubdomainSubmenu from "./SubdomainSubmenu";
const SubdomainWrapper = (props) => {
	const subdomain = props.subdomain;
	return (
		<>
			<Breadcrumb
				elements={[
					{
						to: "/domains",
						title: "Domains",
					},
					{
						to: `/domain/${subdomain?.domain.id}`,
						title: `${subdomain?.domain.name}`,
					},
					{
						to: `/subdomain/${subdomain?.id}`,
						title: `${subdomain?.name}`,
					},
				]}
			></Breadcrumb>

			<div class="row justify-content-evenly">
				<SubdomainSubmenu subdomain={subdomain} />
				<div class="col col-xl-8">{props.children}</div>
			</div>
		</>
	);
};

export default SubdomainWrapper;
