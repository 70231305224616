import React, { useState, useEffect, useContext } from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import apiClient from "../../Services/api";

import { Slack } from "react-bootstrap-icons";

const SlackNotification = (props) => {
	console.log("props are", props);
	const [notification, setNotification] = useState({});

	console.log("notification is", notification);
	if (
		Object.keys(notification).length === 0 &&
		Object.keys(props.notification).length != 0
	) {
		setNotification(props.notification);
	}

	const updateNotificationMeta = (event) => {
		console.log(event.target);
		const value = event.target.value;
		const name = event.target.name;
		const meta = {
			[name]: value,
		};
		setNotification({
			...notification,
			meta,
		});
	};
	const updateNotificationEnabled = (event) => {
		console.log(event.target);
		const value = event.target.checked;
		const name = event.target.name;

		const updatedNotification = {
			...notification,
			[name]: value,
		};
		setNotification(updatedNotification);
		updateNotification(updatedNotification);
	};

	const testNotification = () => {
		props.testNotification(notification);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		updateNotification(notification);
	};
	const updateNotification = (updatedNotification) => {
		props.updateNotification(updatedNotification);
	};

	const connectSlack = () => {
		apiClient
			.get("/api/notification/slack/connect")
			.then((response) => {
				window.location.href = response.data.data;
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const bodyInfo =
		notification && notification.meta && notification.meta.length != 0 ? (
			<p>
				<Slack style={{ marginLeft: "5px" }} size={16} />
				Connected {notification.meta.channel}
				<Button
					variant="danger"
					type="button"
					style={{ float: "right" }}
					onClick={testNotification}
				>
					Test
					<Slack style={{ marginLeft: "5px" }} size={16} />
				</Button>
			</p>
		) : (
			<p>
				<Button
					variant="primary"
					type="button"
					style={{ marginRight: "auto", marginLeft: "auto" }}
					onClick={connectSlack}
				>
					Connect with Slack
					<Slack style={{ marginLeft: "5px" }} size={16} />
				</Button>
			</p>
		);
	let cardBody =
		notification && notification.enabled ? (
			<blockquote className="blockquote mb-0">{bodyInfo}</blockquote>
		) : null;
	return (
		<Form onSubmit={handleSubmit}>
			<h6>
				<i class="flex-shrink-0 bx bxl-slack me-2"></i>Slack Notifications
				<Form.Check
					inline
					style={{ float: "right", marginRight: 0 }}
					type="switch"
					id="custom-switch"
					name="enabled"
					checked={notification && notification.enabled}
					onChange={(e) => updateNotificationEnabled(e)}
				/>
			</h6>

			<div class="py-4">{cardBody}</div>
		</Form>
	);
};

export default SlackNotification;
