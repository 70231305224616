import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import * as dayjs from "dayjs";
import { Context as AuthContext } from "../../context/AuthContext";
import { Context as PusherContext } from "../../context/PusherContext";
import SubdomainWrapper from "./SubdomainWrapper";
import loadSubdomain from "./loadSubdomain";
import apiClient from "../../Services/api";
const SubdomainSSL = (props) => {
	const params = useParams();
	const [subdomain, setSubdomain] = useState(null);
	const { state } = useContext(PusherContext);
	const { logout } = useContext(AuthContext);
	let channel = state.channel;

	if (subdomain) {
		channel.bind(
			"subdomain-updated",
			function (data) {
				if (data.subdomain_id == subdomain.id) {
					loadSubdomain(subdomain.id, setSubdomain, logout);
					//do something when ssl was updated
				}
			}
			// channel.unbind()
		);
	}
	useEffect(() => {
		loadSubdomain(params.id, setSubdomain, logout);
	}, []);
	const reloadSSLInfo = (e) => {
		e.preventDefault();
		apiClient
			.post("/api/subdomain/reload-ssl/" + subdomain.id, {})
			.then((response) => {
				if (response.status === 200) {
				}
			})
			.catch((error) => {
				if (error.response.status == 401) {
					logout();
				}
				console.error(error);
			});
	};
	return (
		<>
			{subdomain ? (
				<SubdomainWrapper subdomain={subdomain}>
					<div class="card mt-2">
						<div class="row g-0 pb-3 ">
							<h5 class="card-header m-0 me-2 pb-3">SSL</h5>
							<div class="d-flex flex-row-reverse px-3">
								<button
									type="button"
									class="mt-0 mb-4 btn btn-primary"
									onClick={reloadSSLInfo}
								>
									<i class="bx bx-sm bx-reset mx-2" ></i>
									Re-check SSL
								</button>
							</div>
							<div class="col-md-6 px-5">
								<div class="mt-3">
									<strong>Subdomain: </strong>
									{subdomain.name}
								</div>
								<div class="mt-3">
									<strong>SSL Port: </strong>
									{subdomain.ssl_port}
								</div>
							</div>
							<div class="col-md-6 px-5">
								<div class="mt-3">
									<strong>SSL Issuer: </strong>

									{subdomain.ssl_issuer}
								</div>
								<div class="mt-3">
									<strong>SSL Expiry Date: </strong>
									{dayjs(subdomain.ssl_expiry_date).format(
										"MMMM D, YYYY h:mm A"
									) +
										" (" +
										dayjs(subdomain.ssl_expiry_date).diff(dayjs(), "days") +
										" days)"}
								</div>
							</div>
						</div>
					</div>
				</SubdomainWrapper>
			) : null}
		</>
	);
};

export default SubdomainSSL;
