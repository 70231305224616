import React from "react";

import ContainerNav from "./ContainerNav";
import Footer from "./Footer";
const MainContainer = ({ children }) => {
	return (
		<>
			<div class="layout-page">
				<ContainerNav></ContainerNav>
				
                <div class="content-wrapper">
					<div class="container-xxl flex-grow-1">{children}</div>
                    <Footer/>
				</div>
			</div>
		</>
	);
};

export default MainContainer;
