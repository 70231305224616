import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import apiClient from "../../Services/api";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Alert from "react-bootstrap/Alert";
import { Context as AlertContext } from "../../context/AlertContext";
import { Context as AuthContext } from "../../context/AuthContext";
import SubdomainWrapper from "./SubdomainWrapper";
import loadSubdomain from "./loadSubdomain";
const SubdomainEdit = (props) => {
	const [subdomain, setSubdomain] = useState(null);
	const { state, setalert } = useContext(AlertContext);
	const { logout } = useContext(AuthContext);
	const params = useParams();
	const navigate = useNavigate();

	console.log("id is", params.id);
	console.log("subdomain is", subdomain);
	
	const handleSubmit = (e) => {
		e.preventDefault();
		apiClient
			.put("/api/subdomains/" + params.id, {
				name: subdomain.name,
				http_check_url: subdomain.http_check_url,
				ssl_port: subdomain.ssl_port,
				settings: subdomain.settings,
			})
			.then((response) => {
				console.log("updated");
				setalert("success", "Subdomain Saved");
				navigate("/subdomain/" + subdomain.id);
			})
			.catch((error) => {
				if (error.response.status == 401) {
					logout();
				}
				console.error(error);
			});
	};
	const updateSubdomainSettings = (event) => {
		const name = event.target.name;
		const settings = subdomain.settings;
		subdomain.settings[name] = !subdomain.settings[name];
		setSubdomain({
			...subdomain,
			settings,
		});
	};
	const updateSubdomain = (event) => {
		const value = event.target.value;
		const name = event.target.name;

		console.log(name, value, {
			...subdomain,
			[name]: value,
		});
		setSubdomain({
			...subdomain,
			[name]: value,
		});
	};

	useEffect(() => {
		loadSubdomain(params.id, setSubdomain, logout);
	}, []);
	return (
		<>
			{subdomain ? (
				<SubdomainWrapper subdomain={subdomain}>
					<div class="card mb-4">
						<h5 class="card-header">
							<i class="bx bx-sm bx-cog"></i>Settings
						</h5>
						<Form onSubmit={handleSubmit}>
							<div class="card-body">
								<div class="mb-3">
									<div class="form-check form-switch mb-2">
										<label
											class="form-check-label"
											for="flexSwitchCheckDefault"
										>
											HTTP Checks
										</label>
										<input
											class="form-check-input"
											type="checkbox"
											id="flexSwitchCheckDefault"
											name="http_check"
											onChange={(e) => updateSubdomainSettings(e)}
											checked={subdomain?.settings?.http_check}
										/>
									</div>
									{subdomain?.settings.http_check ? (
										<>
											<div>
												<label for="defaultInput" class="form-label">
													Http Check URL
												</label>
												<input
													class="form-control form-control-sm"
													placeholder="URL"
													name="http_check_url"
													value={subdomain?.http_check_url}
													onChange={(e) => updateSubdomain(e)}
													required
												/>
											</div>
											<div class="form-check form-switch mb-2">
												<label
													class="form-check-label"
													for="flexSwitchCheckDefault"
												>
													Notifications
												</label>
												<input
													class="form-check-input"
													type="checkbox"
													name="notifications"
													onChange={(e) => updateSubdomainSettings(e)}
													checked={subdomain?.settings.notifications}
												/>
											</div>
										</>
									) : null}
								</div>
								<div class="mb-3">
									<label for="defaultInput" class="form-label">
										SSL PORT
									</label>
									<input
										class="form-control form-control-sm"
										placeholder="Port"
										name="ssl_port"
										value={subdomain?.ssl_port}
										onChange={(e) => updateSubdomain(e)}
										required
									/>
								</div>
								<Button variant="primary" type="submit">
									Save
								</Button>
							</div>
						</Form>

						<hr class="m-0" />
					</div>
				</SubdomainWrapper>
			) : null}
		</>
	);
};

export default SubdomainEdit;
