import React, { useState, useContext } from "react";

import DropdownMenu from "../Helpers/Dropdowns/DropdownMenu";
import { Context as AuthContext } from "../../context/AuthContext";
import AlertMessage from "../Helpers/AlertMessage";
import { NavLink } from "react-router-dom";

import { Context as MenuContext } from "../../context/MenuContext";
const ContainerNav = () => {
	const { openmenu, closemenu } = useContext(MenuContext);

	const { state, logout } = useContext(AuthContext);
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const closeDropdown = () => {
		setDropdownOpen(false);
	};
	const toggleDropdown = () => {
		setDropdownOpen(!dropdownOpen);
	};
	const openMenu = (e) => {
		e.preventDefault();
		console.log("openup");
		openmenu();
	};
	return (
		<nav
			class="layout-navbar container-xxl navbar navbar-expand-xl align-items-center bg-navbar-theme"
			id="layout-navbar"
		>
			<AlertMessage></AlertMessage>
			<div class="layout-menu-toggle navbar-nav align-items-xl-center me-3 me-xl-0 d-xl-none">
				<a class="nav-item nav-link px-0 me-xl-4" onClick={openMenu} href="#">
					<i class="bx bx-menu bx-sm"></i>
				</a>
			</div>
			<div class="app-brand demo" style={{ height: "100%" }}>
				<NavLink to="/" className="menu-link">
					<img src="/images/pingzone-logo.png" style={{ height: "3rem" }} />
				</NavLink>
			</div>
			<div
				class="navbar-nav-right d-flex align-items-center"
				id="navbar-collapse"
			>
				<ul class="navbar-nav flex-row align-items-center ms-auto">
					<li class="nav-item lh-1 me-3 ">
						<NavLink to="/domains" className="menu-link">
							Domains
						</NavLink>
					</li>
					<li class="nav-item lh-1 me-3 navbar-dropdown dropdown-user dropdown">
						<a class="dropdown-item" onClick={toggleDropdown} href="#">
							<i class="bx bx-user me-2"></i>
							<span class="align-middle">{state.user.name}</span>
						</a>
						<DropdownMenu open={dropdownOpen} closeDropdown={closeDropdown}>
							<li>
								<a class="dropdown-item" href="#">
									<div class="d-flex">
										<div class="flex-grow-1">
											<span class="fw-semibold d-block">{state.user.name}</span>
											<small class="text-muted">{state.user.email}</small>
										</div>
									</div>
								</a>
							</li>
							<li>
								<div class="dropdown-divider"></div>
							</li>
							<li>
								<NavLink
									to="/my-profile"
									className="dropdown-item"
									onClick={closeDropdown}
								>
									<i class="bx bx-user me-2"></i>
									<span class="align-middle">My Profile</span>
								</NavLink>
							</li>
							<li>
								<NavLink
									to="/settings"
									className="dropdown-item"
									onClick={closeDropdown}
								>
									<i class="bx bx-cog me-2"></i>
									<span class="align-middle">Settings</span>
								</NavLink>
							</li>
							<li>
								<span class="d-flex align-items-center align-middle">
									<NavLink
										to="/billing"
										className="dropdown-item"
										onClick={closeDropdown}
									>
										<i class="flex-shrink-0 bx bx-credit-card me-2"></i>
										<span class="flex-grow-1 align-middle">Billing</span>
										<span class="flex-shrink-0 badge badge-center rounded-pill bg-danger w-px-20 h-px-20">
											4
										</span>
									</NavLink>
								</span>
							</li>
							<li>
								<div class="dropdown-divider"></div>
							</li>
							<li>
								<a class="dropdown-item" onClick={logout} href="#">
									<i class="bx bx-power-off me-2"></i>
									<span class="align-middle">Log Out</span>
								</a>
							</li>
						</DropdownMenu>
					</li>
				</ul>
			</div>
		</nav>
	);
};

export default ContainerNav;
