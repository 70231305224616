import axios from "axios";

const domain =
	window.location.hostname == "spa.pingme.test"
		? "http://pingme.test"
		: "https://api.ping-zone.com";
const apiClient = axios.create({
	baseURL: domain,
	withCredentials: true,
	headers: {
		"Content-Type": "application/json",
		Accept: "application/json",
	},
});

export default apiClient;
