import Pusher from "pusher-js";
import apiClient from "../Services/api";
Pusher.logToConsole = true;
const PusherObject = new Pusher("3510e8d742ebe161537c", {
	cluster: "eu",
	authorizer: (channel, options) => {
		return {
			authorize: (socketId, callback) => {
				return apiClient
					.post("/api/pusher/auth", {
						socket_id: socketId,
						channel_name: channel.name,
					})
					.then((response) => {
						callback(false, response.data);
					});
			},
		};
	},
});

export default PusherObject;
