import React, { useContext } from "react";

import { Navigate, useParams } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import * as dayjs from "dayjs";
import loadDomain from "./loadDomain";
import { Context as AuthContext } from "../../context/AuthContext";
import DomainWrapper from "./DomainWrapper";
const DomainWhois = (props) => {
	const params = useParams();
	const [domain, setDomain] = React.useState(null);
	const { logout } = useContext(AuthContext);
	React.useEffect(() => {
		loadDomain(params.id, setDomain, logout);
	}, []);

	return (
		<>
			{domain?.dns_info ? (
				<DomainWrapper domain={domain}>
					<div class="card mt-3">
						<h5 class="card-header m-0 me-2 pb-1">Whois</h5>
						<div class="card-body">
							<div class="row">
								<strong>Registrar: </strong>
								<a href={domain.whois["Registrar URL"]} target="_blank">
									{domain.whois["Registrar"]}
								</a>
							</div>
							<div class="row">
								<strong>Registry Expiry Date: </strong>
								{dayjs(domain.whois["Registry Expiry Date"]).format(
									"MMMM D, YYYY h:mm A"
								) +
									" (" +
									dayjs(domain.whois["Registry Expiry Date"]).diff(
										dayjs(),
										"days"
									) +
									" days)"}
							</div>
						</div>
					</div>
				</DomainWrapper>
			) : null}
		</>
	);
};

export default DomainWhois;
