import React, { useContext } from "react";
import Alert from "react-bootstrap/Alert";
import { Context as AlertContext } from "../../context/AlertContext";

const AlertMessage = () => {
	const { state, resetalert } = useContext(AlertContext);

	const alert = state.alert ? (
		<Alert key={state.alert.variant} variant={state.alert.variant} className="nav-alert">
			{state.alert.message}
		</Alert>
	) : null;
	return <>{alert}</>;
};

export default AlertMessage;
