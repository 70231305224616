import React, { useContext, useState, useEffect } from "react";
import { Navigate, useParams, NavLink } from "react-router-dom";
import { Context as AuthContext } from "../../context/AuthContext";
import apiClient from "../../Services/api";
import Breadcrumb from "../Helpers/Breadcrumb";
import SubdomainSSL from "./SubdomainSSL";
import SubdomainWrapper from "./SubdomainWrapper";
import loadSubdomain from "./loadSubdomain";
import * as dayjs from "dayjs";

const Subdomain = (props) => {
	const { logout } = useContext(AuthContext);
	const [subdomain, setSubdomain] = useState(null);
	const params = useParams();
	if (subdomain && subdomain.id != params.id) {
		console.log("relaod subdomain");
		loadSubdomain(params.id, setSubdomain, logout);
	}

	useEffect(() => {
		loadSubdomain(params.id, setSubdomain, logout);
	}, []);

	const getSSLStatus = () => {
		const daysUntilExpire = dayjs(subdomain.ssl_expiry_date).diff(
			dayjs(),
			"days"
		);

		if (daysUntilExpire > 30) {
			return "success";
		}

		if (daysUntilExpire >= 3) {
			return "warning";
		}
		return "danger";
	};
	const getHttpStatus = () => {
		const status = parseInt(subdomain?.http_status);

		console.log("status is ", status);
		if (!subdomain || status < 200) {
			return "";
		}
		if (status >= 200 && status < 300) {
			return "success";
		}
		if (status >= 300 && status < 400) {
			return "warning";
		}
		if (status >= 400) {
			return "danger";
		}
	};
	return subdomain ? (
		<>
			<SubdomainWrapper subdomain={subdomain}>
				<div class="col-lg mt-3">
					<div class="row">
						<div class="col-md-6 col-lg-6 col-xl-6 order-0 mb-4">
							<div className={"card h-100 border border-" + getSSLStatus()}>
								<div class="card-header d-flex align-items-center justify-content-between pb-0">
									<div class="card-title mb-0">
										<h5 class="m-0 me-2">SSL Expiry Date</h5>
									</div>
								</div>
								<div class="card-body">
									<div class="d-flex justify-content-between align-items-center mb-1">
										<div class="d-flex flex-column align-items-center gap-1">
											<span>
												{subdomain.ssl_expiry_date
													? dayjs(subdomain.ssl_expiry_date).format(
															"MMMM D, YYYY h:mm A"
													  ) +
													  " (" +
													  dayjs(subdomain.ssl_expiry_date).diff(
															dayjs(),
															"days"
													  ) +
													  " days)"
													: "N/A"}
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="col-md-6 col-lg-6 col-xl-6 order-0 mb-4">
							<div className={"card h-100 border border-" + getHttpStatus()}>
								<div class="card-header d-flex align-items-center justify-content-between pb-0">
									<div class="card-title mb-0">
										<h5 class="m-0 me-2">HTTP Status</h5>
									</div>
								</div>
								<div class="card-body">
									<div class="d-flex justify-content-between align-items-center mb-1">
										<div class="d-flex flex-column align-items-center gap-1">
											<span>
												{subdomain?.http_info?.status_code}
											</span>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</SubdomainWrapper>
		</>
	) : null;
};

export default Subdomain;
