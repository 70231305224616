import PusherObject from "../Services/Pusher";
import createDataContext from "./createDataContext";

const pusherReducer = (state, action) => {
	switch (action.type) {
		case "load_pusher":
			return { ...state, pusher: action.payload };
		case "load_channel":
			return { ...state, channel: action.payload };
		default:
			return state;
	}
};

const loadpusher = (dispatch) => () => {
	let pusher = PusherObject;

	let userId = localStorage.getItem("userId");
	var channel = pusher.subscribe("private-user." + userId);

	dispatch({
		type: "load_pusher",
		payload: pusher,
	});
	dispatch({
		type: "load_channel",
		payload: channel,
	});
};
export const { Provider, Context } = createDataContext(
	pusherReducer,
	{ loadpusher },
	{ pusher: null, channel: null }
);
