import React, { useState, useEffect, useContext } from "react";
import apiClient from "../../Services/api";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MailNotification from "./MailNotification";
import PushoverNotification from "./PushoverNotification";
import { Context as AlertContext } from "../../context/AlertContext";
import SlackNotification from "./SlackNotification";
const Notifications = () => {
	const [notifications, setNotifications] = useState([]);
	const [activeTab, setActiveTab] = useState(1);
	const { setalert } = useContext(AlertContext);
	useEffect(() => {
		loadNotifications();
	}, []);

	const loadNotifications = () => {
		apiClient
			.get("/api/notifications")
			.then((response) => {
				console.log("data is", response.data.data);
				setNotifications(response.data.data);
			})
			.catch((error) => {
				console.error(error);
			});
	};

	const testNotification = (notification) => {
		console.log("test notification", notification);
		apiClient
			.post("/api/notification/test/" + notification.id)
			.then((response) => {
				setalert("success", "Notification Sent");
			})
			.catch((error) => {
				console.error(error);
			});
	};
	const updateNotification = (updatedNotification) => {
		console.log("submitting", updatedNotification);
		apiClient
			.put("/api/notifications/" + updatedNotification.id, updatedNotification)
			.then((response) => {
				console.log("updated");
				setalert("success", "Changes Saved!");
			})
			.catch((error) => {
				console.error(error);
			});
	};
	const getNotification = (type) => {
		console.log("filter", notifications);
		const fileterdNotifications = notifications.filter((notification) => {
			console.log(notification);
			return notification.type == type;
		});

		console.log("filtered", fileterdNotifications);
		return fileterdNotifications.length > 0 ? fileterdNotifications[0] : {};
	};

	const changeTab = (e) => {
		const tabId = e.currentTarget.getAttribute("data-bs-target");
		setActiveTab(tabId);
	};
	return (
		<>
			<h4 class="fw-bold py-3 mb-4">
				Settings<span class="text-muted fw-light">/Notifications</span>
			</h4>
			<Container>
				<div class="col-xl-12 ">
					<h6 class="text-muted">Filled Tabs</h6>
					<div class="nav-align-top mb-4">
						<ul class="nav nav-tabs nav-fill" role="tablist">
							<li class="nav-item">
								<button
									type="button"
									className={"nav-link " + (activeTab == 1 ? " active" : "")}
									role="tab"
									data-bs-toggle="tab"
									data-bs-target="1"
									aria-selected={activeTab == 1 ? "true" : "false"}
									onClick={changeTab}
								>
									<i class="tf-icons bx bx-envelope"></i> Mail
								</button>
							</li>
							<li class="nav-item">
								<button
									type="button"
									className={"nav-link " + (activeTab == 2 ? " active" : "")}
									role="tab"
									data-bs-toggle="tab"
									data-bs-target="2"
									aria-selected={activeTab == 2 ? "true" : "false"}
									onClick={changeTab}
								>
									<i class="tf-icons bx bxl-slack"></i> Slack
								</button>
							</li>
							<li class="nav-item">
								<button
									type="button"
									className={"nav-link " + (activeTab == 3 ? " active" : "")}
									role="tab"
									data-bs-toggle="tab"
									data-bs-target="3"
									aria-selected={activeTab == 3 ? "true" : "false"}
									onClick={changeTab}
								>
									<i class="tf-icons bx bx-bell"></i>Pushover
								</button>
							</li>
						</ul>
						<div class="tab-content">
							<div
								className={
									"tab-pane fade " + (activeTab == 1 ? " show active" : "")
								}
								id="navs-justified-home"
								role="tabpanel"
							>
								<MailNotification
									testNotification={testNotification}
									notification={getNotification("mail")}
									updateNotification={updateNotification}
								></MailNotification>
							</div>
							<div
								className={
									"tab-pane fade " + (activeTab == 2 ? "show active" : "")
								}
								id="navs-justified-profile"
								role="tabpanel"
							>
								<SlackNotification
									testNotification={testNotification}
									notification={getNotification("slack")}
									updateNotification={updateNotification}
								></SlackNotification>
							</div>
							<div
								className={
									"tab-pane fade " + (activeTab == 3 ? " show active" : "")
								}
								id="navs-justified-messages"
								role="tabpanel"
							>
								<PushoverNotification
									testNotification={testNotification}
									notification={getNotification("pushover")}
									updateNotification={updateNotification}
								></PushoverNotification>
							</div>
						</div>
					</div>
				</div>
			</Container>
		</>
	);
};

export default Notifications;
