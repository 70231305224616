import React from "react";
import { NavLink } from "react-router-dom";

const Breadcrumb = (props) => {
	const elements = props.elements.map((element, i, { length }) => (
		<li className={"breadcrumb-item " + (i + 1 === length ? "active" : "")}>
			<NavLink to={element.to}>{element.title}</NavLink>
		</li>
	));
	return (
		<div class="row justify-content-evenly">
			<div class="col col-xl-10">
				<nav aria-label="breadcrumb" class="py-3 mb-2">
					<ol class="breadcrumb breadcrumb-style1">{elements}</ol>
				</nav>
				{props.children}
			</div>
		</div>
	);
};

export default Breadcrumb;
