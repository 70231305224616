import createDataContext from "./createDataContext";

const alertReducer = (state, action) => {
	switch (action.type) {
		case "update_alert":
			console.log("alert is", action.payload);
			return { ...state, alert: action.payload };
		case "reset_alert":
			return { ...state, alert: null };
		default:
			return state;
	}
};

const setalert = (dispatch) => (variant, message) => {
	console.log(variant, message);
 
	dispatch({
		type: "update_alert",
		payload: { variant, message },
	});
    setTimeout(() => {
        dispatch({
            type: "reset_alert",
            payload: {},
        });
    }, 5000)
};
export const { Provider, Context } = createDataContext(
	alertReducer,
	{ setalert },
	{ alert: null }
);
