import apiClient from "../../Services/api";

const loadDomain = (id, callback, logout) => {
	console.log("loading domain");
	apiClient
		.get("/api/domains/" + id)
		.then((response) => {
			console.log("data is", response.data.data);
			callback(response.data.data);
		})
		.catch((error) => {
			if (error.response.status == 401) {
				logout();
			}
			console.error(error);
		});
};
export default loadDomain;
