import React from "react";

const DropdownItem = (props) => {
	return (
		<a class="dropdown-item" onClick={props.clicked} href="#">
			<i className={"bx me-1 " + props.classes}></i>
			{props.text}
		</a>
	);
};

export default DropdownItem;
