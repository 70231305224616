import React, { useState, useEffect, useContext } from "react";
import DropdownItem from "../Helpers/Dropdowns/DropdownItem";
import DropdownMenu from "../Helpers/Dropdowns/DropdownMenu";
import { NavLink } from "react-router-dom";
import apiClient from "../../Services/api";
import { Context as AlertContext } from "../../context/AlertContext";
import * as dayjs from "dayjs";
const DomainTableRow = (props) => {
	const { setalert } = useContext(AlertContext);
	const domain = props.domain;
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const checkDomainHealth = () => {
		return "Healthy";
	};
	const deleteDomain = () => {
		const confirmation = window.confirm("Are you sure ?");
		if (!confirmation) {
			return false;
		}

		apiClient
			.delete("/api/domains/" + domain.id, {})
			.then((response) => {
				if (response.status === 200) {
					console.log("deleted");
					setDropdownOpen(false);
					setalert("success", "Deleted");
					props.domainDeleted(domain);
				}
			})
			.catch((error) => {
				console.error(error);
				setalert("error", "Failed to delete domain");
				if (error.response.status == 401) {
				}
			});
	};
	const showDropdown = (el) => {
		console.log("clicked", el);
	};

	const toggleDropdown = () => {
		setDropdownOpen(!dropdownOpen);
	};

	const closeDropdown = () => {
		setDropdownOpen(false);
	};

	return (
		<tr>
			<td>
				<NavLink to={"/domain/" + domain.id} style={{ textDecoration: "none" }}>
					<strong>{domain.name}</strong>
				</NavLink>
			</td>
			<td>
				{domain.expiry_date
					? dayjs(domain.expiry_date).format(
							"MMMM D, YYYY h:mm A"
					  ) +
					  " (" +
					  dayjs(domain.expiry_date).diff(dayjs(), "days") +
					  " days)"
					: "N/A"}
			</td>
			<td>{domain.subdomains.length}</td>

			<td>
				<span class="badge bg-label-primary me-1">{checkDomainHealth()}</span>
			</td>
			<td>
				<div class="dropdown">
					<button
						type="button"
						class="btn p-0 dropdown-toggle hide-arrow"
						data-bs-toggle="dropdown"
						onClick={toggleDropdown}
					>
						<i class="bx bx-dots-vertical-rounded"></i>
					</button>
					<DropdownMenu
						key={domain.id}
						open={dropdownOpen}
						closeDropdown={closeDropdown}
					>
						<DropdownItem
							clicked={showDropdown}
							classes="bx-edit-alt"
							key={"edit_" + domain.id}
							text="Edit"
						></DropdownItem>
						<DropdownItem
							clicked={deleteDomain}
							classes="bx-trash"
							key={"delete_" + domain.id}
							text="Delete"
						></DropdownItem>
					</DropdownMenu>
				</div>
			</td>
		</tr>
	);
};

export default DomainTableRow;
