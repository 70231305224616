import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import * as dayjs from "dayjs";
import { Context as AuthContext } from "../../context/AuthContext";
import SubdomainWrapper from "./SubdomainWrapper";
import loadSubdomain from "./loadSubdomain";
const SubdomainNotifications = (props) => {
    const params = useParams();
	const [subdomain, setSubdomain] = useState(null);
	const { logout } = useContext(AuthContext);
    useEffect(() => {
		loadSubdomain(params.id, setSubdomain, logout);
	}, []);
	return (
		<>
			{subdomain ? (
				<SubdomainWrapper subdomain={subdomain}>
					<div class="card mt-2">
						<div class="row g-0 pb-3 ">
							<h5 class="card-header m-0 me-2 pb-3">SSL</h5>
							<div class="col-md-6 px-5">
								<div class="mt-3">
									<strong>Subdomain: </strong>
									{subdomain.name}
								</div>
								<div class="mt-3">
									<strong>SSL Port: </strong>
									{subdomain.ssl_port}
								</div>
							</div>
							<div class="col-md-6 px-5">
								<div class="mt-3">
									<strong>SSL Issuer: </strong>

									{subdomain.ssl_issuer}
								</div>
								<div class="mt-3">
									<strong>SSL Expiry Date: </strong>
									{dayjs(subdomain.ssl_expiry_date).format(
										"MMMM D, YYYY h:mm A"
									) +
										" (" +
										dayjs(subdomain.ssl_expiry_date).diff(dayjs(), "days") +
										" days)"}
								</div>
							</div>
						</div>
					</div>
				</SubdomainWrapper>
			) : null}
		</>
	);
};

export default SubdomainNotifications;
