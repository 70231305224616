import React, { useRef } from "react";
import useOutsideClick from "../outsideClick";

const DropdownMenu = (props) => {
	const handleClickOutside = () => {
		
        props.closeDropdown()
	};

	const ref = useOutsideClick(handleClickOutside);
	return (
		<div
			ref={ref}
			class="dropdown-menu  dropdown-menu-end"
			style={{ display: props.open ? "block" : "none" }}
		>
			{props.children}
		</div>
	);
};

export default DropdownMenu;
