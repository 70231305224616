import React, { useContext } from "react";
import { Navigate, NavLink, Link } from "react-router-dom";
import apiClient from "../Services/api";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Context as AuthContext } from "../context/AuthContext";
import { Context as PusherContext } from "../context/PusherContext";
import Nav from "react-bootstrap/Nav";
const Login = (props) => {
	const [email, setEmail] = React.useState("");
	const [password, setPassword] = React.useState("");

	const [authError, setAuthError] = React.useState(false);
	const [unknownError, setUnknownError] = React.useState(false);
	const { state, login } = useContext(AuthContext);
	const { loadpusher } = useContext(PusherContext);
	const handleSubmit = (e) => {
		e.preventDefault();
		setAuthError(false);
		setUnknownError(false);
		apiClient.get("/sanctum/csrf-cookie").then((response) => {
			apiClient
				.post("/login", {
					email: email,
					password: password,
				})
				.then((response) => {
					if (response.status === 204) {
						apiClient
							.get("/api/user")
							.then((response) => {
								localStorage.setItem("userId", response.data.user.id);
								login(response.data.user);

								loadpusher();
							})
							.catch((error) => {
								console.error(error);

								props.invalidateLogin();
							});
					}
				})
				.catch((error) => {
					if (error.response && error.response.status === 422) {
						setAuthError(true);
					} else {
						setUnknownError(true);
						console.error(error);
					}
				});
		});
	};

	return (
		<div className="authentication-wrapper authentication-basic container-p-y">
			<div class="card">
				<div class="card-body">
					<div class="row text-center">
						<img src="/images/pingzone-logo.png"/>
						
					</div>

					<h4 class="mb-2">Welcome to Ping Zone! 👋</h4>
					<p class="mb-4">
						Please sign-in to your account and start the adventure
					</p>

					<Form onSubmit={handleSubmit}>
						<Form.Group
							className="mb-3"
							controlId="formBasicEmail"
							onSubmit={handleSubmit}
						>
							<Form.Label>Email address</Form.Label>
							<Form.Control
								type="email"
								placeholder="Enter email"
								name="email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								required
								className={authError || unknownError ? "is-invalid" : ""}
							/>

							{authError ? (
								<Form.Control.Feedback type="invalid">
									Credentials not recognised. Please try again.
								</Form.Control.Feedback>
							) : null}
							{unknownError ? (
								<Form.Control.Feedback type="invalid">
									There was an error submitting your details.
								</Form.Control.Feedback>
							) : null}
							
						</Form.Group>
						<div class="mb-3 form-password-toggle">
							<Form.Group className="mb-3" controlId="formBasicPassword">
								<Form.Label>Password</Form.Label>
								<Form.Control
									type="password"
									placeholder="Password"
									name="password"
									value={password}
									onChange={(e) => setPassword(e.target.value)}
									required
								/>
							</Form.Group>
						</div>
						<div class="mb-3">
							<div class="form-check">
								<input
									class="form-check-input"
									type="checkbox"
									id="remember-me"
								/>
								<label class="form-check-label" for="remember-me">
									{" "}
									Remember Me{" "}
								</label>
							</div>
						</div>
						<div class="mb-3">
							<Button variant="primary" type="submit" className="d-grid w-100">
								Submit
							</Button>
						</div>
					</Form>
					<p class="text-center">
						<span>New on our platform? </span>

						<NavLink to={"/register"}>Create an account</NavLink>
					</p>
				</div>
			</div>
		</div>
	);
};

export default Login;
