import React, { useContext } from "react";
import { Navigate, Redirect, NavLink } from "react-router-dom";
import apiClient from "../Services/api";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Context as AuthContext } from "../context/AuthContext";
import { Context as PusherContext } from "../context/PusherContext";
const Register = (props) => {
	const [email, setEmail] = React.useState("");
	const [name, setName] = React.useState("");
	const [password, setPassword] = React.useState("");
	const [passwordConfirm, setPasswordConfirm] = React.useState("");

	const [authError, setAuthError] = React.useState(false);
	const [unknownError, setUnknownError] = React.useState(false);
	const { state, login } = useContext(AuthContext);
	const { loadpusher } = useContext(PusherContext);
	const handleSubmit = (e) => {
		e.preventDefault();
		setAuthError(false);
		setUnknownError(false);
		apiClient.get("/sanctum/csrf-cookie").then((response) => {
			apiClient
				.post("/api/register", {
					email: email,
					name: name,
					password: password,
					password_confirmation: passwordConfirm,
				})
				.then((response) => {
					if (response.status === 201) {
						apiClient
							.get("/api/user")
							.then((response) => {
								localStorage.setItem("userId", response.data.user.id);
								login(response.data.user);

								loadpusher();
							})
							.catch((error) => {
								console.error(error);

								props.invalidateLogin();
							});
					}
				})
				.catch((error) => {
					if (error.response && error.response.status === 422) {
						setAuthError(true);
					} else {
						setUnknownError(true);
						console.error(error);
					}
				});
		});
	};

	return (
		<div className="authentication-wrapper authentication-basic container-p-y">
			<div class="card">
				<div class="card-body">
					<div class="row text-center">
						<img src="/images/pingzone-logo.png" />
					</div>

					<h4 class="mb-2">Adventure starts here! 👋</h4>

					<Form onSubmit={handleSubmit}>
						<Form.Group
							className="mb-3"
							controlId="formBasicName"
							onSubmit={handleSubmit}
						>
							<Form.Label>Email address</Form.Label>
							<Form.Control
								type="text"
								placeholder="Enter name"
								name="name"
								value={name}
								onChange={(e) => setName(e.target.value)}
								required
								className={authError || unknownError ? "is-invalid" : ""}
							/>

							{unknownError ? (
								<Form.Control.Feedback type="invalid">
									There was an error submitting your details.
								</Form.Control.Feedback>
							) : null}
						</Form.Group>
						<Form.Group
							className="mb-3"
							controlId="formBasicEmail"
							onSubmit={handleSubmit}
						>
							<Form.Label>Email address</Form.Label>
							<Form.Control
								type="email"
								placeholder="Enter email"
								name="email"
								value={email}
								onChange={(e) => setEmail(e.target.value)}
								required
								className={authError || unknownError ? "is-invalid" : ""}
							/>

							{unknownError ? (
								<Form.Control.Feedback type="invalid">
									There was an error submitting your details.
								</Form.Control.Feedback>
							) : null}
						</Form.Group>

						<Form.Group className="mb-3" controlId="formBasicPassword">
							<Form.Label>Password</Form.Label>
							<Form.Control
								type="password"
								placeholder="Password"
								name="password"
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								required
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="formBasicPassword">
							<Form.Label>Password Confirm</Form.Label>
							<Form.Control
								type="password"
								placeholder="Password Confirm"
								name="passwordConfirm"
								value={passwordConfirm}
								onChange={(e) => setPasswordConfirm(e.target.value)}
								required
							/>
						</Form.Group>
						<div class="mb-3">
							<Button variant="primary" type="submit" className="d-grid w-100">
								Sign Up
							</Button>
						</div>
					</Form>
					<p class="text-center">
						<span>Already have an account? </span>

						<NavLink to={"/login"}>Sign in instead</NavLink>
					</p>
				</div>
			</div>
		</div>
	);
};

export default Register;
