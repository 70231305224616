import React, { useState } from "react";

import apiClient from "../../Services/api";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

const CreateDomainForm = (props) => {
	const [domain, setDomain] = React.useState("");
	const [show, setShow] = useState(false);

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	const handleSubmit = (e) => {
		e.preventDefault();
		apiClient
			.post("/api/domains", {
				name: domain,
			})
			.then((response) => {
				if (response.status === 200) {
					props.addDomain(response.data.data);
                    setDomain("");
                    handleClose();
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};
	return (
		<>
			<Button variant="primary" onClick={handleShow} className="m-4">
				Add New Domain
			</Button>

			<Modal
				show={show}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}
			>
				<Form onSubmit={handleSubmit}>
					<Modal.Header closeButton>
						<Modal.Title>Add New Domain</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Form.Group className="mb-3" controlId="formBasicDomain">
							<Form.Label>Name</Form.Label>
							<Form.Control
								type="text"
								placeholder="Domain Name"
								name="domain"
								value={domain}
								onChange={(e) => setDomain(e.target.value)}
								required
							/>
						</Form.Group>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							Close
						</Button>
						<Button variant="primary" type="submit">
							Save
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};

export default CreateDomainForm;
