import React, { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import apiClient from "../../Services/api";
import { Navigate, NavLink } from "react-router-dom";
import CreateDomainForm from "../Forms/CreateDomainForm";
import Badge from "react-bootstrap/Badge";
import ListGroup from "react-bootstrap/ListGroup";
import { Context as AuthContext } from "../../context/AuthContext";
import { Context as PusherContext } from "../../context/PusherContext";
import DomainTableRow from "./DomainTableRow";
const Domains = (props) => {
	const [domains, setDomains] = useState([]);
	const { state } = useContext(PusherContext);
	let channel = state.channel;

	if (channel) {
		channel.bind(
			"domain-updated",
			function (data) {
				console.log("pusher update, calling loadDomain");
				loadDomains();
			},
			channel.unbind()
		);
	}
	const [showCreateDomainForm, setShowCreateDomainForm] = useState();
	const { logout } = useContext(AuthContext);
	const addDomain = (domain) => {
		console.log(domain);

		setDomains((domains) => [...domains, domain]);
	};
	const setShowCreateDomainFormActive = () => {
		console.log("active");
		setShowCreateDomainForm(true);
	};
	const domainDeleted = (domain) => {
		console.log("domain was deleted", domain);

		const updatedDomains = domains.filter((el) => el.id != domain.id);
		setDomains(updatedDomains);
	};
	const loadDomains = () => {
		apiClient
			.get("/api/domains")
			.then((response) => {
				setDomains(response.data.data);
			})
			.catch((error) => {
				if (error.response.status == 401) {
					logout();
				}
				console.error(error);
			});
	};
	React.useEffect(() => {
        loadDomains();
    }, []);

	const domainsList = domains.map((domain) => (
		<DomainTableRow
			domain={domain}
			domainDeleted={domainDeleted}
		></DomainTableRow>
	));

	console.log("domains list", domainsList);

	return (
		<>
			<CreateDomainForm addDomain={addDomain} />

			<div class="card">
				<div class="table-responsive text-nowrap">
					<table class="table">
						<thead>
							<tr>
								<th>Domain</th>
								<th>Expiry Date</th>
								<th>Subdomains</th>
								<th>Status</th>

								<th>Actions</th>
							</tr>
						</thead>
						<tbody class="table-border-bottom-0">{domainsList}</tbody>
					</table>
				</div>
			</div>
		</>
	);
};

export default Domains;
