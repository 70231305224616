import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { useNavigate } from "react-router-dom";

import apiClient from "../../Services/api";

const CreateSubdomainForm = (props) => {
	const [subdomain, setSubdomain] = React.useState("");
	const [show, setShow] = useState(false);
	let navigate = useNavigate();

	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const handleSubmit = (e) => {
		e.preventDefault();
		apiClient
			.post("/api/subdomains", {
				name: subdomain,
				domain_id: props.domain.id,
			})
			.then((response) => {
				if (response.status === 200) {
					props.addSubdomain(response.data.data);
					setSubdomain("");
					handleClose();
					navigate(`/subdomain/${response.data.data.id}/edit`);
				}
			})
			.catch((error) => {
				console.error(error);
			});
	};
	return (
		<>
			<Button variant="primary" onClick={handleShow} className="mt-0 mb-4">
				Add New Subdomain
			</Button>

			<Modal
				show={show}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}
			>
				<Form onSubmit={handleSubmit}>
					<Modal.Header closeButton>
						<Modal.Title>Add New Subdomain</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<InputGroup className="mb-3">
							<Form.Control
								type="text"
								placeholder="Subdomain Name"
								name="subdomain"
								value={subdomain}
								onChange={(e) => setSubdomain(e.target.value)}
								required
							/>
							<InputGroup.Text id="basic-addon2">
								.{props.domain.name}
							</InputGroup.Text>
						</InputGroup>
					</Modal.Body>
					<Modal.Footer>
						<Button variant="secondary" onClick={handleClose}>
							Close
						</Button>
						<Button variant="primary" type="submit">
							Save
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};

export default CreateSubdomainForm;
