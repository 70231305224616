import React from "react";
import Breadcrumb from "../Helpers/Breadcrumb";
import DomainSubmenu from "./DomainSubmenu";
const DomainWrapper = (props) => {
	const domain = props.domain;
	return (
		<>
			<Breadcrumb
				elements={[
					{
						to: "/domains",
						title: "Domains",
					},
					{
						to: `/domain/${domain.id}`,
						title: `${domain.name}`,
					},
				]}
			></Breadcrumb>

			<div class="row justify-content-evenly">
				<DomainSubmenu domain={domain} />
				<div class="col col-xl-8">{props.children}</div>
			</div>
		</>
	);
};

export default DomainWrapper;
