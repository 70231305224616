import React, { useContext } from "react";

import apiClient from "../../Services/api";
import { Navigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import * as dayjs from "dayjs";
import loadDomain from "./loadDomain";
import { Context as AuthContext } from "../../context/AuthContext";
import DomainWrapper from "./DomainWrapper";
const DomainDns = (props) => {
	const params = useParams();
	const [domain, setDomain] = React.useState(null);
	const { logout } = useContext(AuthContext);
	React.useEffect(() => {
		loadDomain(params.id, setDomain, logout);
	}, []);

	if (domain && domain.id != params.id) {
		console.log("relaod domain");
		loadDomain(params.id, setDomain, logout);
	}

	let records = [];
	if (domain) {
		const dnsInfo = Object.keys(domain?.dns_info)
			.sort()
			.reduce((obj, key) => {
				obj[key] = domain.dns_info[key];
				return obj;
			}, {});

		Object.keys(dnsInfo)
			// .filter((k, i) => k != "NS")
			.forEach((k) => {
				dnsInfo[k].map((data) => {
					records.push(data);
				});
			});
	}

	const dnsTableRow = records.map((data) => (
		<tr>
			<td>
				<span class="badge bg-label-warning me-1">{data.type}</span>
			</td>
			<td>
				<div>{data.name}</div>
				<div>{data.value}</div>
			</td>

			<td>
				<span class="badge bg-label-primary me-1">{data.ttl / 60} Minutes</span>
			</td>
		</tr>
	));

	return (
		<>
			{domain?.dns_info ? (
				<DomainWrapper domain={domain}>
					<div class="card mt-3">
						
						<h5 class="card-header m-0 me-2 pb-1">DNS Records</h5>
						<div class="col-md-12 px-5">
							<table class="table table-sm dns-table">
								<thead>
									<tr>
										<th>Type</th>
										<th>Record</th>
										<th>TTl</th>
									</tr>
								</thead>
								<tbody class="table-border-bottom-0">{dnsTableRow}</tbody>
							</table>
						</div>
					</div>
				</DomainWrapper>
			) : null}
		</>
	);
};

export default DomainDns;
