import React, { useEffect, useContext} from "react";
import { Context as PusherContext } from "../context/PusherContext";
const PusherLoader = () => {
	const { loadpusher } = useContext(PusherContext);
    useEffect(() => {
        loadpusher();
    }, []);


};

export default PusherLoader;
