import createDataContext from "./createDataContext";

const menuReducer = (state, action) => {
	switch (action.type) {
		case "open_menu":
			return { ...state, menuOpen: true };
		case "close_menu":
			return { ...state, menuOpen: false };
		case "select_domain":
			return { ...state, domain: action.payload.domain };
		default:
			return state;
	}
};

const openmenu = (dispatch) => () => {
	console.log("opening menu");
	dispatch({
		type: "open_menu",
		payload: {},
	});
};
const closemenu = (dispatch) => () => {
	console.log("closing menu");
	dispatch({
		type: "close_menu",
		payload: {},
	});
};

const selectdomain = (dispatch) => (domain) => {
	console.log("selecting  domain", domain);
	dispatch({
		type: "select_domain",
		payload: domain,
	});
};

export const { Provider, Context } = createDataContext(
	menuReducer,
	{ openmenu, closemenu, selectdomain },
	{ menuOpen: false, showMenu: false, selectedDomain: false }
);
