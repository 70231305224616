import React, { useContext } from "react";

import { Navigate, useParams } from "react-router-dom";
import CreateSubdomainForm from "../Forms/CreateSubdomainForm";
import Subdomain from "../Subdomains/SubdomainTableRow";
import Button from "react-bootstrap/Button";
import Subdomains from "../Subdomains/Subdomains";
import { Context as AuthContext } from "../../context/AuthContext";
import { Context as PusherContext } from "../../context/PusherContext";

import DomainWhois from "./DomainWhois";
import DomainDns from "./DomainDns";
import loadDomain from "./loadDomain";
import DomainWrapper from "./DomainWrapper";
const Domain = (props) => {
	const [domain, setDomain] = React.useState(null);
	const [showDomainInfo, setShowDomainInfo] = React.useState(true);
	const [subdomains, setSubdomains] = React.useState([]);
	const [domainDeleted, setDomainDeleted] = React.useState(null);
	const { logout } = useContext(AuthContext);
	const { state } = useContext(PusherContext);

	let channel = state.channel;
	const params = useParams();
	if (channel) {
		channel.bind(
			"subdomain-updated",
			function (data) {
				console.log("pusher update, calling loadDomain");
				loadDomain(params.id, setDomain, logout);
			},
			channel.unbind()
		);
	}

	if (domain && domain.id != params.id) {
		console.log("relaod domain");
		loadDomain(params.id, setDomain, logout);
	}

	const toggleShowDomainInfo = () => {
		setShowDomainInfo(!showDomainInfo);
	};
	const [showCreateSubdomainForm, setShowCreateSubdomainForm] =
		React.useState();

	const addSubdomain = (subdomain) => {
		setSubdomains((subdomain) => [...subdomains, subdomain]);
		domain.subdomains = [...domain.subdomains, subdomain];
		setDomain(domain);
	};

	const subdomainDeleted = (subdomain) => {
		console.log("remove", subdomain);
		const subdomainsUpdated = subdomains.filter((el) => el.id != subdomain.id);
		setSubdomains(subdomainsUpdated);
		domain.subdomains = domain.subdomains.filter((el) => el.id != subdomain.id);
		setDomain(domain);
	};

	React.useEffect(() => {
		loadDomain(params.id, setDomain, logout);
	}, []);

	if (domainDeleted) {
		return <Navigate to="/domains" />;
	}

	if (domain) {
		const setShowCreateSubdomainFormActive = () => {
			console.log("active");
			setShowCreateSubdomainForm(true);
		};

		return (
			<DomainWrapper domain={domain}>
				<h5 class="pb-1 mb-0 mt-1">Subdomains</h5>
				<div class="d-flex flex-row-reverse">
					<CreateSubdomainForm addSubdomain={addSubdomain} domain={domain} />
				</div>

				<Subdomains
					subdomains={domain.subdomains}
					subdomainDeleted={subdomainDeleted}
				></Subdomains>
			</DomainWrapper>
		);
	}
};

export default Domain;
