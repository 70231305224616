import React, { useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Pusher from "pusher-js";
import "./App.css";
import "./Core.css";
import "./fonts/Boxicons.css";
import "./ThemeDefault.css";

import { Provider as UserProvider } from "./context/UserContext";
import { Provider as AuthProvider } from "./context/AuthContext";
import { Provider as AlertProvider } from "./context/AlertContext";
import { Provider as PusherProvider } from "./context/PusherContext";
import { Provider as MenuProvider } from "./context/MenuContext";
import Router from "./Router/Router";

export default function App() {
	return (
		<>
			<AlertProvider>
				<AuthProvider>
					<UserProvider>
						<PusherProvider>
							<MenuProvider>
								<Router></Router>
							</MenuProvider>
						</PusherProvider>
					</UserProvider>
				</AuthProvider>
			</AlertProvider>
		</>
	);
}
