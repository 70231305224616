import React from "react";
const Footer = () => {
	return (
		<footer class="footer bg-light">
			<div class="container-fluid d-flex flex-md-row flex-column justify-content-between align-items-md-center gap-1 container-p-x py-3">
				<div>Ping Zone ©</div>
				<div>
					<a
						href="https://themeselection.com/license/"
						class="footer-link me-4"
						target="_blank"
					>
						License
					</a>
					<a href="javascript:void(0)" class="footer-link me-4">
						Help
					</a>
					<a href="javascript:void(0)" class="footer-link me-4">
						Contact
					</a>
					<a href="javascript:void(0)" class="footer-link">
						Terms &amp; Conditions
					</a>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
